<div class="container">
  <div class="filters" *ngIf="visualiza_aereo">
    <h4>{{"Filtrar e Ordenar" | translate }}</h4>
    <div class="price">
      <h5 class="mt-3">{{"Preço" | translate}}</h5>
      <ng5-slider [ngClass]="visualiza_aereo ? 'invisible' : 'visible' " [(highValue)]="FiltroAereo.ValorMaximo"
        [options]="OpcoesValor" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      <h5 class="mt-3">{{"Faixas de horário" | translate}}</h5>
      <h6 class="mt-3">{{"Ida" | translate}}</h6>
      <ng5-slider [(value)]="FiltroAereo.HorarioIdaMinimo" [(highValue)]="FiltroAereo.HorarioIdaMaximo"
        [options]="OpcoesHorario" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      <h6 class="mt-3">{{"Volta" | translate}}</h6>
      <ng5-slider [(value)]="FiltroAereo.HorarioVoltaMinimo" [(highValue)]="FiltroAereo.HorarioVoltaMaximo"
        [options]="OpcoesHorario" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      <h5 class="mt-3">{{"Duração" | translate}}</h5>
      <div *ngFor="let o of OpcoesDuracaoTrechos; let i = index">
        <h6>Trecho {{ (i+1) }}</h6>
        <ng5-slider [(value)]="FiltroAereo.MinimoDuracaoTrechos[i]" [options]="o"
          [(highValue)]="FiltroAereo.MaximoDuracaoTrechos[i]" (userChangeEnd)="buscarDebounce()"></ng5-slider>
      </div>

      <h6 class="mt-3">{{"Pernoite" | translate}}?</h6>
      <div>
        <label class="d-flex align-items-center" for="pernoite" style="column-gap: 0.5rem;">
          <input class="m-0 p-0" type="checkbox" [(ngModel)]="FiltroAereo.SemPernoite"
            [checked]="FiltroAereo.SemPernoite" (ngModelChange)="buscarDebounce()" />
          <span class="icheck-title ml-3"><span class="bold">{{"Sem Pernoite" | translate}}</span></span>
        </label>
      </div>

      <h6 class="mt-3">{{"Número de Paradas - Ida" | translate}}</h6>
      <form>
        <select class="form-select" placeholder="{{'Selecione' | translate}}" [(ngModel)]="FiltroAereo.NumeroParadasIda"
          (ngModelChange)="buscarDebounce()" name="bagagem">
          <option value="-1">{{"Todos" | translate }}</option>
          <option value="0">{{"Direto" | translate}}</option>
          <option value="1">1 {{"escala/conexão" | translate}} </option>
          <option value="2">2 {{"escalas/conexões" | translate}}</option>
          <option value="3">3 {{"escalas/conexões" | translate}}</option>
        </select>
      </form>
      <h6 class="mt-3">{{"Número de Paradas - Volta" | translate}}</h6>
      <form>
        <select class="form-select" placeholder="{{'Selecione' | translate}}" [(ngModel)]="FiltroAereo.NumeroParadasVolta"
          (ngModelChange)="buscarDebounce()" name="bagagem">
          <option value="-1">{{"Todos" | translate}}</option>
          <option value="0">{{"Direto" | translate}}</option>
          <option value="1">1 {{"escala/conexão" | translate}}</option>
          <option value="2">2 {{"escalas/conexões" | translate}}</option>
          <option value="3">3 {{"ou mais escalas/conexões" | translate}}</option>
        </select>
      </form>

      <h6 class="mt-3">{{"Número de Voo" | translate}}</h6>
      <form class="form-inline">
        <tag-input [(ngModel)]="Voos" secondaryPlaceholder="{{'Número do Voo' | translate}}" maxItems="6" name="Voos"></tag-input>
      </form>
      <!-- <input id="nVoo_Search" [(ngModel)]="Voos" value="" type="text" class="form-control" placeholder="Número do voo"> -->
      <div class="mt-4" *ngIf="busca.usuario.EmpresaId != null">
        <h6>{{"Cias" | translate}}</h6>

        <div *ngFor="let cia of Cias; let k = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="cia-{{cia}}" name="cia-{{cia}}"
              (change)="selecionarCia(cia)" [checked]="ciaSelecionada(cia)" />
            <img style="max-width: 60px" src="assets/img/cias/{{cia}}.png" title="{{cia}}">
          </div>

        </div>
      </div>

      <div class=" mt-4" *ngIf="busca.usuario.EmpresaId != null">
        <h6>{{"Fornecedores" | translate}}</h6>

        <div *ngFor="let fornecedor of Fornecedores; let k = index">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="fornecedor-{{fornecedor}}"
              name="fornecedor-{{fornecedor}}" (change)="selecionarFornecedor(fornecedor)"
              [checked]="FornecedorSelecionado(fornecedor)" />
            <img style="max-width: 60px" src="assets/img/cias/{{fornecedor}}.png" title="{{fornecedor}}">
          </div>

        </div>
      </div>


      <div class="bagagem">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text mx-0" for="inputGroupSelect01">{{"Bagagem" | translate}}</label>
          </div>
          <select [(ngModel)]="FiltroAereo.Bagagem" class="custom-select" id="inputGroupSelect01">
            <option class="dropdown-item" *ngFor="let i of [].constructor(5), let x = index">{{ x }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="filterButton">
      <button (click)="refazerBusca(busca)" type="button" class="btn btn-success">{{"Filtrar" | translate}}</button>
    </div>
  </div>

  <div class="resultList">
    <div class="alert alert-danger" role="alert"
      *ngIf="busca.trechos[0].AeroportoChegada.Cidade.Nome == (null || undefined || '')">
      {{"Revise os dados da busca e realize a rebusca!" | translate}}
    </div>

    <div *ngIf="buscando">
      <div class="row">
        <ng-container *ngFor="let integrador of buscasAtivas">
          <div class="col col-md-3" style="vertical-align: middle;display:inline-flex">
            <p>{{ integrador }}</p>
            <img src="assets/img/loading1.gif" />
          </div>
        </ng-container>
      </div>
    </div>

    <div class='row nav-options' [hidden]="buscando || (Orcamento.length == 0)" *ngIf="busca.ReservaId > 0">
      <div class='col-4 btn-avancar'>
        <button [disabled]="buscando || (Orcamento.length == 0)" class="btn btn-success" (click)="rotaAvancar()">
          <a>Avançar <i class="fas fa-chevron-right"></i></a>
        </button>
      </div>
    </div>
    <div *ngIf="!buscando">
      <div class="row">
        <div class="col-12 mb-3">
          <nav class="nav nav-pills d-flex justify-content-start flex-column flex-sm-row" style="column-gap: 6rem;">
            <button class="btn w-25 text-sm-center position-relative"
              [ngClass]="visualiza_aereo ? 'selecionado' : 'btn-outline-success'" [disabled]="bloqueios.length === 0" (click)="alterarExibicao()">
              Aereos
              <span
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">{{paginacao?.TotalItens}}</span>
            </button>
            <button *ngIf="bloqueios.length > 0"  class="btn w-25 text-sm-center position-relative"
              [ngClass]="!visualiza_aereo ? 'selecionado' : 'btn-outline-success'" (click)="alterarExibicao()">
              Bloqueios
              <span
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">{{bloqueios.length}}</span>
            </button>
          </nav>
        </div>
      </div>

      <div *ngIf="visualiza_aereo">
        <div *ngFor="let aereo of aereos" [ngClass]="visualiza_aereo ? 'aereo-bloqueio' : ''">
          <app-aereo [aereo]="aereo" [usuario]="busca.usuario" (selecionouAereo)="selecionarAereo($event)"
            (removeuAereo)="removerAereo($event)"></app-aereo>
        </div>
        <p *ngIf="((aereos.length === 0 && !buscando))">{{"Esta busca não retornou nenhum resultado." | translate}}</p>
      </div>

      <div *ngIf="!visualiza_aereo">
        <div *ngFor="let bloqueio of bloqueios" [ngClass]="!visualiza_aereo ? 'aereo-bloqueio' : ''">
          <app-bloqueio [bloqueio]="bloqueio" [busca]="busca" [usuario]="busca.usuario"
            (selecionouAereo)="selecionarAereo($event)" (removeuAereo)="removerAereo($event)"></app-bloqueio>
        </div>
        <p *ngIf="((bloqueios.length === 0 && !buscando))">{{"Esta busca não retornou nenhum resultado." | translate}}
        </p>
      </div>

      <div *ngIf="visualiza_aereo" class="justify-content-center col-md-12 col-lg-12 col-xxxl-12">
        <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
      </div>

    </div>
  </div>
</div>