<div class="container all">
  <div class="filterss">
    <h4>{{"Filtrar e Ordenar" | translate }}</h4>
    <div class="input">
      <h6>{{"Pesquisar" | translate}}</h6>
      <input id="nomeServicoSearch" [(ngModel)]="FiltroServico.Titulo" value="" type="text" class="form-control"
        placeholder="{{'Nome do Serviço' | translate}}">
    </div>
    <div class="input">
      <h6>{{'Tipos' | translate}}</h6>
      <!-- <select class="form-control" placeholder="{{'Selecione' | translate}}" [(ngModel)]="FiltroServico.Tipo" name="tipo"
        style="min-width: 200px">
        <option value="">{{ 'Todos' | translate}}</option>
        <option *ngFor="let tipo of tipos">{{tipo}}</option>
      </select> -->
      <select class="form-control" [(ngModel)]="FiltroServico.Tipo" name="tipo" style="min-width: 200px">
        <option value="">{{'Selecione' | translate}}</option>
        <option value="">{{ 'Todos' | translate}}</option>
        <option *ngFor="let tipo of tipos">{{tipo}}</option>
    </select>
    </div>
    <div class="input">
      <h6>{{"Categorias" | translate}}</h6>
      <!-- <select class="form-control" placeholder="{{'Selecione' | translate}}" [(ngModel)]="FiltroServico.Categoria" name="categoria"
        style="min-width: 200px">
        <option value="-1">{{ 'Todos' | translate}}</option>
        <option value="0">{{ 'Regular' | translate}}</option>
        <option value="1">{{ 'Privativo' | translate}}</option>
        <option value="2">{{ 'Luxo' | translate}}</option>
      </select> -->
      <select class="form-control" [(ngModel)]="FiltroServico.Categoria" name="categoria" style="min-width: 200px">
        <option value="" disabled selected>{{ 'Selecione' | translate }}</option>
        <option value="-1">{{ 'Todos' | translate }}</option>
        <option value="0">{{ 'Regular' | translate }}</option>
        <option value="1">{{ 'Privativo' | translate }}</option>
        <option value="2">{{ 'Luxo' | translate }}</option>
    </select>
    </div>
    <div class="faixa-preco-hotel mt-2">
      <p>{{"Preço" | translate}}</p>
      <ng5-slider [(highValue)]="FiltroServico.ValorMaximo" [options]="OpcoesValor"></ng5-slider>
    </div>
    <div class="filterButton">
      <button (click)="refazerBusca(busca)" type="button" class="btn btn-success">{{"Filtrar" | translate}}</button>
    </div>
  </div>
  <div class="ms-1 resultList">
    <div *ngIf="carregando">
      <app-view-loading></app-view-loading>
    </div>
    <div *ngIf="buscando">
      <div class="row">
        <ng-container *ngFor="let integrador of buscasAtivas">
          <div class="col col-md-3" style="vertical-align: middle;display:inline-flex">
            <p>{{ integrador }}</p>
            <img src="assets/img/loading1.gif" />
          </div>
        </ng-container>
      </div>
    </div>
    <div class='row nav-options' [hidden]="buscando || (Orcamento.length == 0)">
      <div class='col text-end btn-avancar'>
        <button [disabled]="buscando || Orcamento.length == 0" class="btn btn-success" (click)="rotaAvancar()">
          <a>{{"Avançar" | translate}}<i class="fas fa-chevron-right"></i></a>
        </button>
      </div>
    </div>
    <div *ngIf="!buscando">
      <div class="servico" *ngFor="let servico of servicos">
        <div class="hotel-cards">
          <app-servico [buscando]="buscando" [servico]="servico" [usuario]="busca.usuario"
            (selecionouServico)="selecionarServico($event)" (removeuServico)="removerServico($event)">
          </app-servico>
        </div>
      </div>

      <p *ngIf="servicos.length == 0 && !buscando">{{"Esta busca não retornou nenhum resultado." | translate}}</p>

      <div class="justify-content-center col-md-12 col-lg-12 col-xxxl-12">
        <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
      </div>
    </div>
  </div>

</div>
