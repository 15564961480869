<div class="footer-container">
  <div class="text-center" id="logo">
    <img [src]="faviconlight" style="width: 5vw;">
  </div>
  <div>
    <p class="text-center">{{"Rua" | translate}} Marechal Floriano Peixoto, 1455 - Centro, Foz do Iguaçu - PR, 85851-020 - PR <br> (45)3521-7508
    </p>
    <!-- [innerHTML]="enderecotelefone" -->
  </div>
  <div class="text-center">
    <a [href]="wpp"><i class="fab fa-whatsapp"></i></a>
    <a [href]="instagram" target="_blank"><i class="fab fa-instagram"></i></a>
    <a [href]="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a>
  </div>
</div>
