<app-view-loading *ngIf="carregando"></app-view-loading>

<div class="gera-orcamento" *ngIf="!carregando">
    <div class="container" *ngIf="!Consultor">
        <app-selecionar-consultor *ngIf="Orcamento.AgenciaId" [reserva]="Orcamento"
            (selecionou)="selecionaConsultor($event)"></app-selecionar-consultor>
    </div>
    <div class="container" *ngIf="Consultor">
        <div class="d-flex align-items-center mb-1">
            <div class="dropdown">
                <!-- <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-print fs-5" title="Email/Download/Impressão"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <a class="dropdown-item" role="button" (click)="orcamentoOpcoesSimplificado()">Simplificado</a>
          </li>
          <li>
            <a class="dropdown-item" role="button" (click)="orcamentoOpcoesCompleto()"> Completo</a>
          </li>
        </ul> -->
            </div>
            <div class='flex me-4' *ngIf="Orcamento.Status == 6 && !Orcamento.LiberarVoucher && liberar_voucher">
                <a style="cursor:pointer" (click)="confirmarLiberar()"><i class="fas fa-unlock"></i>{{"Liberar Voucher" | translate }}</a>
            </div>
            <div style="display: contents;">
                {{'Consultor:' | translate}} {{ Orcamento.Consultor?.Nome + " " + Orcamento.Consultor?.Sobrenome }}
                <a href="" data-bs-toggle="modal" #selecionaAgente data-bs-target="#selecionaConsultor"><i
                        class="far fa-edit" id="consultor"></i> {{'Alterar' | translate}}</a>
                <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <i class="fas fa-print fs-5 ms-4" title="Email/Download/Impressão"></i>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a class="dropdown-item" role="button"
                                (click)="orcamentoOpcoesSimplificado()">{{"Simplificado" |
                                translate }}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" role="button" (click)="orcamentoOpcoesCompleto()"> {{"Completo" |
                                translate
                                }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="info-header row">
                <div class="col logo-empresa">
                    <img
                        src="{{ apiLocale + Orcamento.Agencia.Empresa.LogoInternet != null && apiLocale + Orcamento.Agencia.Empresa.LogoInternet != '' ? apiLocale + Orcamento.Agencia.Empresa.LogoInternet :  backgroundimage}}" />
                </div>
                <div class="col">
                    <h5>{{ Orcamento.Agencia.NomeFantasia}}</h5>
                    <p>{{ Orcamento.Agencia.Logradouro + ', ' + Orcamento.Agencia.Numero + ', ' +
                        Orcamento.Agencia.Cidade.Nome +
                        ' - ' + Orcamento.Agencia.Cidade.Estado.Iata }} </p>
                </div>
                <div class="col">
                    <h6>{{"Agente" | translate}}</h6>
                    <p>{{ Orcamento.Agente.Email }}</p>
                    <div
                        *ngIf="Orcamento?.Servicos[0]?.EmailConsultor != null && Orcamento?.Servicos[0]?.CodigoCliente != null">
                        <h6>{{"Consultor no cliente" | translate }}:</h6>
                        <p>{{ Orcamento?.Servicos[0]?.EmailConsultor }}</p>
                    </div>
                    <div
                        *ngIf="Orcamento?.Servicos[0]?.EmailConsultor != null && Orcamento?.Servicos[0]?.CodigoCliente != null">
                        <h6>{{"Orçamento no cliente" | translate }}:</h6>
                        <p>{{ orcamentocliente }}</p>
                    </div>
                </div>
                <div class="col">
                    <div>
                        <h6>{{"Criado em" | translate }}:</h6>
                        <p>{{ Orcamento.Alteracoes.CriadoEm | date: "mediumDate" }}</p>
                    </div>
                    <div>
                        <h6>{{"Criado por" | translate }}:</h6>
                        <p>{{ Orcamento.Alteracoes.CriadoPor }}</p>
                    </div>
                </div>
                <div class="col-1">
                    <h6 class="txt-r">{{"Orçamento Nº" | translate}} {{+ this.idTEMP}}</h6>
                    <br>
                    <button class="btn btn-primary"
                        *ngIf="usaLamina && (Orcamento.Hoteis?.length > 0 || Orcamento.HotelPacote?.length > 0)"
                        (click)="abrirModalLaminas(contentLaminas)">{{"Gerar Lâmina" | translate}}</button>
                </div>
            </div>
            <div class="info-body">
                <div class="passageiros">
                    <p class="title text-center"><i class="fas fa-user-friends"></i> {{"Passageiros" | translate}}</p>
                    <div *ngFor="let quarto of Orcamento.Quartos ; let i = index">
                        <h5>{{"Quarto" | translate}} {{quarto.quarto}}</h5>
                        <div class="row" *ngFor="let pessoa of quarto.pessoas">
                            <div class='col'>
                                <div class='row'>
                                    <div class="col-1">
                                        <div>
                                            <label>{{"Tipo" | translate}}</label>
                                            <input type="text" class="form-control" aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default" [disabled]="true"
                                                [value]="pessoa.Tipo == 0 ? 'ADT' : (pessoa.Tipo == 1 ? 'CHD' : 'INF')"
                                                name="tipoPessoa">
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="mb-3">
                                            <label>{{"Nome" | translate}}</label>
                                            <input type="text" class="form-control" aria-label="Default"
                                                autocomplete="off" aria-describedby="inputGroup-sizing-default"
                                                [(ngModel)]="pessoa.Nome" name="Nome">
                                            <span class="form-text">{{ validaCaracterEspecial(pessoa.Nome) ? ("Não é permitido caracteres especiais" | translate) : ''}}</span>
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div>
                                            <label>{{"Sobrenome" | translate}}</label>
                                            <input type="text" class="form-control" aria-label="Default"
                                                autocomplete="off" aria-describedby="inputGroup-sizing-default"
                                                [(ngModel)]="pessoa.Sobrenome" name="sobrenome">
                                            <span class="form-text">{{ validaCaracterEspecial(pessoa.Sobrenome) ? ("Não é permitido caracteres especiais" | translate) : ''}}</span>
                                        </div>
                                    </div>

                                    <div class="col-2">
                                        <div>
                                            <label>{{"Dt. Nascimento" | translate }}</label>
                                            <input type="text" class="form-control" aria-label="Default"
                                                autocomplete="off" aria-describedby="inputGroup-sizing-default"
                                                mask="00/00/0000" placeholder="dd/mm/aaaa"
                                                [(ngModel)]="pessoa.Nascimento" name="inputDataNascimento"
                                                [class.erroBorda]="!pessoa.IdadeCertaVoltaViagem"
                                                (input)="setDataNascimento(pessoa)">
                                        </div>
                                    </div>

                                    <div class="col-2">

                                        <div>
                                            <label>{{"Gênero" | translate}}</label>
                                            <select class="custom-select form-select" [(ngModel)]="pessoa.Sexo">
                                                <option selected disabled>Selecione</option>
                                                <option value="M">{{"Masculino" | translate}}</option>
                                                <option value="F">{{"Feminino" | translate}}</option>
                                            </select>
                                            <span *ngIf="!pessoa.Sexo" style="color:red">Selecione uma opção</span>
                                        </div>
                                    </div>

                                    <div class="col-1" *ngIf="pessoa.Tipo == 1">
                                        <label>{{"Idade" | translate}}</label>
                                        <div>
                                            <input type="text" class="form-control" aria-label="Default"
                                                aria-describedby="inputGroup-sizing-default" [(ngModel)]="pessoa.Idade"
                                                name="idade" disabled [class.erroBorda]="!pessoa.IdadeCertaVoltaViagem">
                                        </div>
                                    </div>
                                </div>
                                <div class='row'>
                                    <div class="col-3  offset-md-1">
                                        <div class="mb-3">
                                            <label>{{"CPF" | translate}}</label>
                                            <input type="text" class="form-control" aria-label="Default"
                                                autocomplete="off" mask="000.000.000-00"
                                                aria-describedby="inputGroup-sizing-default" [(ngModel)]="pessoa.CPF"
                                                name="CPF">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <label>{{"E-mail" | translate}}</label>
                                            <input type="email" class="form-control" aria-label="Default"
                                                autocomplete="off" aria-describedby="inputGroup-sizing-default"
                                                [(ngModel)]="pessoa.Email" name="Email">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="mb-3">
                                            <label>{{"Telefone" | translate}}</label>
                                            <input type="text" class="form-control" aria-label="Default"
                                                autocomplete="off" mask="(00)000000009"
                                                aria-describedby="inputGroup-sizing-default"
                                                [(ngModel)]="pessoa.Telefone" name="Telefone">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row text-end">

                        <div class="col-md-12 _mt-20" *ngIf="mensagemIdadeCertaVoltaViagem">
                            <div class="alert alert-danger alert-dismissible" style="width: 100%">
                                <a href="#" class="close" data-bs-dismiss="alert" aria-label="close">&times;</a>
                                <div [innerHTML]="mensagemIdadeCertaVoltaViagem"> </div>
                            </div>
                        </div>

                        <div class="col-md-12 _mt-20" *ngIf="mensagensErroPessoas">
                            <div class="alert alert-danger alert-dismissible" style="width: 100%">
                                <a href="#" class="close" data-bs-dismiss="alert" aria-label="close">&times;</a>
                                <ul>
                                    <li *ngFor="let mensagem of mensagensErroPessoas">{{mensagem}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class='col-md-12 mt-4' *ngIf="Orcamento.Status != 6 || alterapaxcomreserva">
                            <button class="btn btn-success" (click)="salvarDadosPessoas()"><i class="fas fa-check"></i>
                                {{"Confirmar passageiros" | translate}}</button>
                        </div>
                    </div>
                </div>

                <div class="aereo">
                    <div class='row d-flex'>
                        <div class='col-4 flex-start'>
                            <button class="btn btn-outline-secondary" (click)="adicionarItem($event, 'aereo')"
                                *ngIf="Orcamento.Status !=6"><i class="fas fa-plus-circle"></i> {{"Alterar aéreo" | translate}}</button>
                        </div>
                        <div class='col-4 text-center flex-center'>
                            <p class="title"><i class="fas fa-plane"></i> {{"Aéreo" | translate }}</p>
                        </div>
                    </div>
                    <div class="col-4" *ngIf="Orcamento.Status !=6">
                    </div>
                    <div *ngFor="let aereo of Orcamento.Aereos, let a = index" style="padding-top: 40px">
                        <div>
                            <div class='row'>
                                <div class='col flex as-fe'>
                                    <div class="col flex" [ngSwitch]="aereo.Status">
                                        <h6>{{ "Status: " | translate}}</h6>
                                        <span *ngSwitchCase="0" class="badge rounded-pill bg-secondary">{{"Pendente" |
                                            translate}}</span>
                                        <span *ngSwitchCase="1" class="badge rounded-pill bg-secondary">{{"Aguardando" |
                                            translate }}</span>
                                        <span *ngSwitchCase="2" class="badge rounded-pill bg-secondary">{{"Reserva
                                            Parcial" | translate
                                            }}</span>
                                        <span *ngSwitchCase="3" class="badge rounded-pill bg-success">{{"Reservado" |
                                            translate }}</span>
                                        <span *ngSwitchCase="4" class="badge rounded-pill bg-success">{{"Solicitado" |
                                            translate }}</span>
                                        <span *ngSwitchCase="5" class="badge rounded-pill bg-success">{{"Emitido" |
                                            translate }}</span>
                                        <span *ngSwitchCase="6" class="badge rounded-pill bg-success">{{"Pago" |
                                            translate }}</span>
                                        <span *ngSwitchCase="7"
                                            class="badge rounded-pill bg-warning text-dark">{{"Problema Técnico" |
                                            translate }}</span>
                                        <span *ngSwitchCase="8"
                                            class="badge rounded-pill bg-warning text-dark">{{"Cancelado" | translate
                                            }}</span>
                                        <span *ngSwitchCase="9"
                                            class="badge rounded-pill bg-warning text-dark">{{"Excluído" | translate
                                            }}</span>
                                        <span *ngSwitchCase="10"
                                            class="badge rounded-pill bg-warning text-dark">{{"Cancelamento com Problema" | translate }}</span>
                                    </div>
                                </div>

                                <div class='col flex text-center' *ngIf="aereo.LocalizadorAereo">
                                    <h6>{{"Localizador" | translate}}: </h6>
                                    <p> {{aereo.LocalizadorAereo}} </p>
                                </div>

                                <div class='col text-end as-fe'>
                                    <button *ngIf="aereo.Status == 0 || aereo.Status == 8"
                                        class="btn btn-outline-danger" (click)="confirmarRemoverAereo(aereo)">{{
                                        "Excluir" | translate }}</button>

                                    <button class="btn btn-outline-danger"
                                        *ngIf="aereo.Status == 3 && Orcamento.Status!=6 && !agente_nao_reserva"
                                        (click)="carregar(aereo, 'cancelaraereo')">{{"Cancelar" | translate }}</button>
                                    <button #cancelarAereo data-bs-toggle="modal" data-bs-target="#cancelarAereo"
                                        style="display: none;"></button>

                                    <button class="btn btn-success"
                                        *ngIf="aereo.Status == 0 && aereo.PaxesTarifa?.length > 0 && (aereo?.Erros?.length == 0 || aereo?.Erros == null) && !agente_nao_reserva"
                                        (click)="carregar(aereo, 'reservaraereo')">{{"Reservar" | translate}}</button>
                                    <button #reservarAereo data-bs-toggle="modal" data-bs-target="#reservarAereo"
                                        style="display: none;"></button>

                                    <button class="btn btn-success"
                                        *ngIf="aereo.Status == 3 && !agente_nao_reserva && (aereo?.Erros?.length == 0 || aereo?.Erros == null)"
                                        (click)="carregar(aereo, 'emitiraereo')" [disabled]="Orcamento.Status!=6"
                                        title="{{(Orcamento.Status==6 ? 'Emitir Bilhete' : 'Necessário pagar o orçamento para emitir') | translate}}">{{
                                        "Emitir" |
                                        translate}}</button>
                                    <button #emitirAereo data-bs-toggle="modal" data-bs-target="#emitirAereo"
                                        style="display: none;"></button>

                                    <button class="btn btn-success" *ngIf="aereo.Status == 5 && Orcamento.Status==6"
                                        (click)="imprimirVoucher('aereo',aereo)" [disabled]="!Orcamento.LiberarVoucher">
                                        <span *ngIf="!Orcamento.LiberarVoucher"
                                            class="fas fa-exclamation-circle"></span>
                                        <span *ngIf="Orcamento.LiberarVoucher" class="fas fa-check-circle"></span>
                                        {{Orcamento.LiberarVoucher ? 'Voucher' : 'Voucher Pendente'}}
                                    </button>
                                </div>

                            </div>
                        </div>

                        <div [ngClass]="{'disableDiv' : Orcamento.Aereos[0].Status == 8 }">
                            <app-orcamento-aereo-card [aereo]="aereo"></app-orcamento-aereo-card>
                        </div>
                        <div *ngIf="aereo.Erros?.length > 0" class="row d-flex justify-content-center">
                            <ul>
                                <li *ngFor="let m of aereo.Erros">
                                    <strong style="color: red;">{{m}}</strong>
                                </li>
                            </ul>
                        </div>
                        <div *ngIf="aereo.PaxesTarifa?.length > 0" class="row d-flex justify-content-center">
                            <div class="table">
                                <table class="table-responsive w-100">
                                    <tr style="font-weight: 500; text-align: center; ">
                                        <td>{{"Tipo" | translate}}</td>
                                        <td>{{"Qntd" | translate}}</td>
                                        <td>{{"Tarifa(un)" | translate}}</td>
                                        <td>{{"Taxas de serviço(un)" | translate}}</td>
                                        <td>{{"Taxas" | translate}}</td>
                                        <td *ngIf="temRC(aereo.PaxesTarifa)">RC(un)</td>
                                        <td>{{"Valor por" | translate}} PAX</td>
                                        <td>{{"Subtotal" | translate}}</td>
                                    </tr>
                                    <tr *ngFor="let tarifa of aereo.PaxesTarifa" class="text-center">
                                        <td>{{(tarifa.TipoPax == 0 ? 'ADT' : (tarifa.TipoPax == 1 ? 'CHD' : 'INF')) |
                                            translate}}
                                        </td>
                                        <td>{{tarifa.QtdPax}}</td>
                                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTarifaListagem |
                                            currency:'BRL':'':'1.2-2' }}
                                        </td>
                                        <td>{{aereo.MoedaListagem.Sigla}}
                                            {{tarifa.ValorTaxaServicoListagem | currency:'BRL':'':'1.2-2' }}
                                        </td>
                                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.ValorTaxaListagem |
                                            currency:'BRL':'':'1.2-2' }}
                                        </td>
                                        <td *ngIf="temRC(aereo.PaxesTarifa)">{{aereo.MoedaListagem.Sigla}}
                                            {{tarifa.ValorTaxaDUListagem | currency:'BRL':'':'1.2-2' }}
                                        </td>
                                        <td>{{aereo.MoedaListagem.Sigla}}
                                            {{tarifa.ValorTarifaListagem + tarifa.TotalTaxasListagem +
                                            tarifa.ValorTaxaDUListagem| currency:'BRL':'':'1.2-2' }}
                                        </td>
                                        <td>{{aereo.MoedaListagem.Sigla}} {{tarifa.SubTotalListagem |
                                            currency:'BRL':'':'1.2-2' }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row" style="padding: 0px 15px">
                            <div class="col-md-8" style="align-self: flex-end;">
                                <strong>{{'Data Inclusão' | translate}}:</strong> {{aereo.Alteracoes.CriadoEm | date
                                :'dd/MM/yyyy HH:mm:ss'}} |
                                <strong *ngIf="aereo.Status != 5">{{'Data Limite' | translate}}:</strong>
                                {{aereo.LimiteTempoEmissao | date :'dd/MM/yyyy HH:mm:ss'}}
                                <p *ngIf="aereo.PrazoCancelamentoWs != null"><strong>{{'Prazo de Cancelamento' | translate}}:</strong>
                                    {{aereo.PrazoCancelamentoWs | date:'dd/MM/yyyy HH:mm:ss'}}
                                </p>
                            </div>
                            <!-- <div class="col-md-4 text-right" style="padding-right:40px">
                <strong class="texto-Preco">{{aereo.MoedaListagem.Sigla}}
                  {{aereo.ValorListagem | currency:'BRL':'':'2.2-2'}}</strong><br>
                <p style="font-size: 13px;">({{aereo.MoedaListagem.Sigla}}
                  {{aereo.TaxasListagemTotal | currency:'BRL':'':'2.2-2'}} de taxas)
                </p>
              </div> -->
                        </div>
                    </div>
                </div>

                <div class="hospedagem">
                    <div class='row'>
                        <div class='col'>
                            <button class="btn btn-outline-secondary" *ngIf='Orcamento.Status !=6'
                                (click)="adicionarItem($event, 'hotel')"><i class="fas fa-plus-circle"></i> {{"Adicionar Hotel" | translate}}
                                </button>
                        </div>
                        <div class='col text-center'>
                            <p class="title"><i class="fas fa-concierge-bell"></i> {{"Hospedagem" | translate}} </p>
                        </div>
                        <div class='col'>
                        </div>
                    </div>
                    <div *ngFor="let hotel of Orcamento.Hoteis, let k = index">
                        <div class="row header-row">
                            <div class="col">
                                <h5>{{"Hospedagem (Opção)" | translate}} {{hotel.Opcao}} <span
                                        class="badge badge-primary"
                                        *ngIf='Orcamento.Hoteis[k].PacoteId != null'>Pacote</span></h5>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col title">
                                <strong>{{ hotel.Nome }}</strong>
                                <strong *ngIf="usuario.EmpresaId"> {{ ' - ' + hotel.Fornecedor }} </strong>
                            </div>
                            <div class="col subtitle">
                                <strong>{{ noites(hotel.Checkin, hotel.Checkout) }} {{"Noites" | translate}}</strong>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h6>{{ hotel.Endereco }}</h6>
                                <div class="row mb">
                                    <div class='col'>
                                        <p> {{"Cidade" | translate}}:
                                            <span>
                                                {{hotel?.Cidade?.Nome}}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col flex">
                                                <h6>{{'Check-In' | translate}}:</h6>
                                                <p>{{ hotel.Checkin | date: 'dd/MM/yyyy' }}</p>
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col flex">
                                                <h6>{{ "Check-out" | translate}}:</h6>
                                                <p>{{ hotel.Checkout | date: 'dd/MM/yyyy' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <div class="col flex" [ngSwitch]="hotel.Status">
                                                <h6>{{ "Status" | translate}}:</h6>
                                                <span *ngSwitchCase="0"
                                                    class="badge rounded-pill bg-secondary">{{"Pendente" | translate
                                                    }}</span>
                                                <span *ngSwitchCase="1"
                                                    class="badge rounded-pill bg-secondary">{{"Aguardando" | translate
                                                    }}</span>
                                                <span *ngSwitchCase="2"
                                                    class="badge rounded-pill bg-secondary">{{"Reserva Parcial" |
                                                    translate}}</span>
                                                <span *ngSwitchCase="3"
                                                    class="badge rounded-pill bg-success">{{"Reservado" | translate
                                                    }}</span>
                                                <span *ngSwitchCase="4"
                                                    class="badge rounded-pill bg-success">{{"Solicitado" | translate
                                                    }}</span>
                                                <span *ngSwitchCase="5"
                                                    class="badge rounded-pill bg-success">{{"Emitido" | translate
                                                    }}</span>
                                                <span *ngSwitchCase="6" class="badge rounded-pill bg-success">{{"Pago" |
                                                    translate }}</span>
                                                <span *ngSwitchCase="7"
                                                    class="badge rounded-pill bg-warning text-dark">{{"Problema Técnico"
                                                    |
                                                    translate }}</span>
                                                <span *ngSwitchCase="8"
                                                    class="badge rounded-pill bg-warning text-dark">{{"Cancelado" |
                                                    translate
                                                    }}</span>
                                                <span *ngSwitchCase="9"
                                                    class="badge rounded-pill bg-warning text-dark">{{"Excluído" |
                                                    translate
                                                    }}</span>
                                                <span *ngSwitchCase="10"
                                                    class="badge rounded-pill bg-warning text-dark">{{"Cancelamento com Problema" | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col flex">
                                                <h6>{{"Reembolsável" | translate }}:</h6>
                                                <p> {{ hoteis[k].Quartos[0].Reembolsavel ? 'Sim' : 'Não' | translate}} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="room mt-3 border-bottom" *ngFor="let quarto of hoteis[k].Quartos, let j = index">
                            <div class="row">
                                <div class="col-6 flex">
                                    <strong>{{"Quarto " + (j + 1) + " / " + quarto.Adultos + " Adulto(s)" | translate }}
                                        {{quarto.Criancas > 0
                                        ? "/ " + quarto.Criancas + " Criança(s)" : "" }} :</strong>
                                </div>
                                <div class="col-6 text-end" *ngIf="hotel.Quartos[j].Localizador">
                                    Loc: <strong>{{ hotel.Quartos[j].Localizador }} <p *ngIf="hotel.Agrupador"
                                            style="display: inline;">/ {{hotel.Agrupador}}</p></strong>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col"><b>{{"Quantidade" | translate }}:</b>
                                    <p>{{ hotel.Quartos[j].Quantidade }}</p>
                                </div>
                                <div class="col"><b>{{"Apartamento" | translate }}:</b>
                                    <p>{{ hotel.Quartos[j].Descricao | translate }}</p>
                                </div>
                                <div class="col"><b>{{"Regime" | translate }}: </b>
                                    <p>{{ hotel.Quartos[j].DescricaoPensao | translate }}</p>
                                </div>
                            </div>
                            <div class='row mt-3'>
                                <div class="col"><b>{{ "Subtotal" | translate }}: </b>
                                    <p>{{hotel.Quartos[j].MoedaListagem.Simbolo}} {{ hotel.Quartos[j].ValorListagem |
                                        currency:'BRL':'':'2.2-2' }}</p>
                                </div>
                                <div class="col"><b>{{ "Prazo Cancelamento" | translate }}: </b>
                                    <p>{{ hotel.Quartos[j].PrazoCancelamento | date: "dd/MM/yyyy" }}</p>
                                </div>
                                <div class="col">
                                    <div *ngIf="Orcamento.Agente.EmpresaId != ('' || null || undefined)">
                                        <b>{{ "Prazo Cancelamento WS" | translate }}:</b>
                                        <p>{{ hotel.Quartos[j].PrazoCancelamentoWs | date: "dd/MM/yyyy"}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                *ngIf="quarto.PoliticaCancelamento != '' || quarto.PoliticaCancelamento != null">
                                <details>
                                    <summary><strong>{{"Política de Cancelamento" | translate}}</strong></summary>
                                    <!-- <p class="text-justify" [innerHTML]="quarto.PoliticaCancelamento"></p> -->
                                    <p class="text-justify">{{"Cancelamento: Cancelamento sem multa em até 72h da realização do serviço" | translate}}.</p>
                                </details>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col text-end mt-2">
                                <strong>{{ "Total" | translate }}: </strong>
                                <strong class="price">{{hotel.Quartos[0].MoedaListagem.Simbolo}} {{
                                    hotel.ValorTotalListagem |
                                    currency:'BRL':'':'2.2-2' }}</strong>
                                <!-- <h4>{{hotel.Quartos[0].MoedaListagem.Simbolo}} {{ somaTotal(hotel.Quartos) | currency:'BRL':'':'2.2-2' }}</h4> -->
                            </div>
                        </div>
                        <div class="row footer-row">
                            <div class="col text-end">
                                <button class="btn btn-outline-danger" (click)="removerHotel(hotel)"
                                    [disabled]="carregandoObj" *ngIf="hotel.Status == 0 || hotel.Status == 8">
                                    {{ "Excluir" | translate }}
                                </button>
                                <button class="btn btn-outline-danger" (click)="cancelarHotel(hotel)"
                                    [disabled]="carregandoObj" *ngIf="hotel.Status == 3 && Orcamento.Status!=6">
                                    {{ "Cancelar" | translate }}
                                </button>

                                <button *ngIf="hotel.Status == 0" class="btn btn-success"
                                    (click)="carregar(hotel, 'hotel')">{{ "Reservar" |
                                    translate }}</button>
                                <button #reservarHotel data-bs-toggle="modal" data-bs-target="#reservarHotel"
                                    style="display: none;"></button>

                                <button *ngIf="hotel.Status == (1 || 2 || 10)" class="btn btn-success"
                                    [disabled]="hotel.carregando" (click)="atualizarStatusHotel(hotel)"
                                    data-bs-toggle="modal" data-bs-target="#reservarHotel">{{
                                    "Verificar" | translate }}</button>

                                <button *ngIf="(hotel.Status == 3 || hotel.Status == 4) && Orcamento.Status==6"
                                    class="btn btn-success" (click)="imprimirVoucher('hotel',hotel)"
                                    [disabled]="!Orcamento.LiberarVoucher">
                                    <span *ngIf="!Orcamento.LiberarVoucher" class="fas fa-exclamation-circle"></span>
                                    <span *ngIf="Orcamento.LiberarVoucher" class="fas fa-check-circle"></span>
                                    {{Orcamento.LiberarVoucher ? 'Voucher' : 'Voucher Pendente'}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="servico">
                    <div class='row'>
                        <div class='col'>
                            <button class="btn btn-outline-secondary" *ngIf="Orcamento.Status != 6"
                                (click)="adicionarItem($event, 'servico')"><i class="fas fa-plus-circle"></i>
                                {{"Adicionar Serviço" | translate }}</button>
                        </div>
                        <div class='col text-center'>
                            <p class="title"><i class="fas fa-route"></i> {{ "Serviço" | translate }}</p>
                        </div>
                        <div class='col'>
                        </div>
                    </div>
                    <div *ngFor="let servico of servicos, let a = index">
                        <div class="row header-row">
                            <div class="col">
                                <h5>{{"Serviço" | translate}} {{a + 1}}</h5>
                            </div>
                        </div>
                        <div class="row" id="description">
                            <div class="col-5" [ngSwitch]="servico.CategoriaServico">
                                <strong class="title">
                                    {{ servico.Titulo }}
                                    <span *ngSwitchCase="0">({{'REGULAR' | translate}})</span>
                                    <span *ngSwitchCase="1">({{'PRIVATIVO' | translate}})</span>
                                    <span *ngSwitchCase="2">({{'LUXO' | translate}})</span>
                                </strong>
                                <strong class="subtitle" *ngIf="usuario.EmpresaId != null">({{ servico.Fornecedor
                                    }})</strong>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="flex">
                                            <strong>{{"Pessoas" | translate }}:</strong>
                                            <p>{{ servico.Adt + " " + ( servico.Adt > 1 ? "Adultos" : "Adulto")}}
                                                {{servico.Chd > 0 ? "/ " +
                                                servico.Chd + " Criança(s)" : "" }}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="flex">
                                            <strong>{{"Data" | translate}}:</strong>
                                            <p>{{ servico.Ate | date: 'dd/MM/yyyy' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="flex">
                                            <strong>{{"Cidade" | translate}}:</strong>
                                            <p>{{ servico.Cidade.Nome }}</p>
                                        </div>
                                    </div>
                                    <div class="col" *ngIf="servico?.ExtraIntegracao2?.NomeFornecedor">
                                        <div class="flex">
                                            <strong>{{"Fornecedor" | translate}}:</strong>
                                            <p> {{servico?.ExtraIntegracao2?.NomeFornecedor}} </p>
                                        </div>
                                        <!-- <div class="flex">
                      <strong>{{'CNPJ:' | translate}}</strong>
                      <p> {{servico?.ExtraIntegracao2?.NomeFornecedor}} </p>
                    </div> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="flex">
                                            <!-- <strong>{{'Status:' | translate}}</strong>
                      <p>{{ servico.StatusDesc }}</p> -->
                                            <div class="col flex" [ngSwitch]="servico.Status">
                                                <h6>{{ "Status: " | translate}}</h6>
                                                <span *ngSwitchCase="0"
                                                    class="badge rounded-pill bg-secondary">Pendente</span>
                                                <span *ngSwitchCase="1"
                                                    class="badge rounded-pill bg-secondary">Aguardando</span>
                                                <span *ngSwitchCase="2" class="badge rounded-pill bg-secondary">Reserva
                                                    Parcial</span>
                                                <span *ngSwitchCase="3"
                                                    class="badge rounded-pill bg-success">Reservado</span>
                                                <span *ngSwitchCase="4"
                                                    class="badge rounded-pill bg-success">Solicitado</span>
                                                <span *ngSwitchCase="5"
                                                    class="badge rounded-pill bg-success">Emitido</span>
                                                <span *ngSwitchCase="6"
                                                    class="badge rounded-pill bg-success">Pago</span>
                                                <span *ngSwitchCase="7"
                                                    class="badge rounded-pill bg-warning text-dark">Problema
                                                    Técnico</span>
                                                <span *ngSwitchCase="8"
                                                    class="badge rounded-pill bg-warning text-dark">Cancelado</span>
                                                <span *ngSwitchCase="9"
                                                    class="badge rounded-pill bg-warning text-dark">Excluído</span>
                                                <span *ngSwitchCase="10"
                                                    class="badge rounded-pill bg-warning text-dark">Cancelamento com
                                                    Problema</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="flex">
                                            <strong>Loc.:</strong>
                                            <p>{{ servico.Localizador }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="flex">
                                            <strong>{{'Prazo Cancelamento:' | translate}}</strong>
                                            <p>{{ servico.PrazoCancelamento | date: "dd/MM/yyyy" }}</p>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="flex"
                                            *ngIf="Orcamento.Agente.EmpresaId != ('' || null || undefined)">
                                            <strong>{{'Prazo Cancelamento Ws:' | translate}} </strong>
                                            <p>{{ servico.PrazoCancelamentoWs | date: "dd/MM/yyyy" }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col" *ngIf="servico?.ExtraIntegracao2?.CategoriaServico">
                                        <div class="flex">
                                            <strong>{{'Categoria:' | translate}}</strong>
                                            <p> {{servico?.ExtraIntegracao2?.CategoriaServico}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-end">
                                <div class="flex">
                                    <strong>{{'Subtotal:' | translate}}</strong>
                                    <p>{{servico.MoedaListagem.Simbolo}} {{ servico.ValorListagem | currency:'BRL':'':'2.2-2' }} </p>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="servico.PoliticaCancelamento != '' || servico.PoliticaCancelamento != null">
                            <details>
                                <!-- <summary><strong>{{'Política de Cancelamento' | translate}}</strong></summary>
                                <p class="text-justify" [innerHTML]="servico.PoliticaCancelamento"></p> -->
                                <summary><strong>{{"Política de Cancelamento" | translate}}</strong></summary>
                                    <p class="text-justify">{{"Cancelamento: Cancelamento sem multa em até 72h da realização do serviço" | translate}}.</p>
                            </details>
                        </div>
                        <div class="row">
                            <details>
                                <summary><strong>{{'Observações' | translate}}</strong></summary>
                                <p class="text-justify" [innerHTML]="servico.Descricao"></p>
                            </details>
                        </div>
                        <div class="row">
                            <details>
                                <summary><strong>{{'Perguntas' | translate}}</strong></summary>
                                <div class="mt-4 row" *ngIf="servico.Perguntas && servico.Perguntas.length > 0">
                                    <div class="col col-md-3" *ngFor="let pergunta of servico.Perguntas">
                                        <h6>{{pergunta.Pergunta}}</h6>
                                        <p>R: {{pergunta.Resposta}}</p>
                                    </div>
                                </div>
                            </details>
                        </div>
                        <div class="row">
                            <div class="col text-end">
                                <strong>{{"Total" | translate}}: </strong>
                                <strong class="price">{{servico.MoedaListagem.Simbolo}} {{ servico.ValorListagem | currency:'BRL':'':'2.2-2'
                                    }}</strong>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col">
                                <p>{{"Adicionado em" | translate}}: {{ servico.Alteracoes.CriadoEm | date:'dd/MM/yyyy'}}</p>
                            </div>
                            <div class="col text-end" id="buttons">
                                <button class="btn btn-outline-danger"
                                    *ngIf="servico.Status == 0 || servico.Status == 8"
                                    (click)="removerServico(servico)">{{ "Excluir" | translate }}</button>

                                <button *ngIf="(servico.Status == (3 || 4) && Orcamento.Status != 6)"
                                    class="btn btn-outline-danger" data-bs-toggle="modal"
                                    data-bs-target="#cancelarServico" (click)="carregar(servico, 'cancelarservico')">{{
                                    "Cancelar" | translate }}</button>

                                <button *ngIf="servico.Status == 0" type="button" class="btn btn-success"
                                    (click)="carregar(servico, 'servico')">Reservar</button>
                                <button #reservarServico data-bs-toggle="modal" data-bs-target="#reservarServico"
                                    style="display: none;"></button>

                                <button *ngIf="(servico.Status == 3 || servico.Status == 4) && Orcamento.Status==6"
                                    type="button" class="btn btn-success" (click)="imprimirVoucher('servico',servico)"
                                    [disabled]="!Orcamento.LiberarVoucher">
                                    <span *ngIf="!Orcamento.LiberarVoucher" class="fas fa-exclamation-circle"></span>
                                    <span *ngIf="Orcamento.LiberarVoucher" class="fas fa-check-circle"></span>
                                    {{Orcamento.LiberarVoucher ? 'Voucher' : 'Voucher Pendente'}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="usaCarro" class="carro">
                    <div class="row">
                        <div class='col'>
                            <button class="btn btn-outline-secondary" *ngIf="Orcamento.Status != 6"
                                (click)="adicionarItem($event, 'carro')"><i class="fas fa-plus-circle"></i> {{"Adicionar Carro" | translate }}</button>
                        </div>
                        <div class='col text-center'>
                            <p class="title"><i class="fas fa-car"></i> {{ "Carro" | translate }}</p>
                        </div>
                        <div class='col'>
                        </div>
                    </div>
                    <div class="mb-4" *ngFor="let carro of carros; let indice = index">
                        <div class="row header-row">
                            <div class="col">
                                <h5>{{"Carro" | translate}} {{indice+ 1}}</h5>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col title d-flex flex-column align-items-start">
                                <h5>{{ carro.Descricao }} <span *ngIf="usuario.EmpresaId">{{ ' - ' + carro.Fornecedor }}</span></h5>
                                <strong style="font-size: 14px;">{{"Proteções Escolhidas" | translate}}</strong>
                                <span style="font-size: 12px;" class="text-success text-center" *ngFor="let protecao of carro.Protecoes">
                                    {{protecao.Titulo}} ({{protecao.Codigo}}) - {{carro.MoedaListagem.Sigla}} {{protecao.ValorNet | currency: carro.MoedaListagem.Sigla :'':'2.2-2'}}
                                </span>

                                <div class="row mt-3">
                                    <div class="col flex" [ngSwitch]="carro.Status">
                                        <h6>{{ "Status" | translate}}:</h6>
                                        <span *ngSwitchCase="0"
                                            class="badge rounded-pill bg-secondary">{{"Pendente" | translate
                                            }}</span>
                                        <span *ngSwitchCase="1"
                                            class="badge rounded-pill bg-secondary">{{"Aguardando" | translate
                                            }}</span>
                                        <span *ngSwitchCase="2"
                                            class="badge rounded-pill bg-secondary">{{"Reserva Parcial" |
                                            translate}}</span>
                                        <span *ngSwitchCase="3"
                                            class="badge rounded-pill bg-success">{{"Reservado" | translate
                                            }}</span>
                                        <span *ngSwitchCase="4"
                                            class="badge rounded-pill bg-success">{{"Solicitado" | translate
                                            }}</span>
                                        <span *ngSwitchCase="5"
                                            class="badge rounded-pill bg-success">{{"Emitido" | translate
                                            }}</span>
                                        <span *ngSwitchCase="6" class="badge rounded-pill bg-success">{{"Pago" |
                                            translate }}</span>
                                        <span *ngSwitchCase="7"
                                            class="badge rounded-pill bg-warning text-dark">{{"Problema Técnico"
                                            |
                                            translate }}</span>
                                        <span *ngSwitchCase="8"
                                            class="badge rounded-pill bg-warning text-dark">{{"Cancelado" |
                                            translate
                                            }}</span>
                                        <span *ngSwitchCase="9"
                                            class="badge rounded-pill bg-warning text-dark">{{"Excluído" |
                                            translate
                                            }}</span>
                                        <span *ngSwitchCase="10"
                                            class="badge rounded-pill bg-warning text-dark">{{"Cancelamento com Problema" | translate }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <strong>{{"Data e Hora de Retirada" | translate}}</strong>:
                                <p>{{carro.DataHoraOrigem | date: "dd/MM/yyyy HH:mm"}}</p>
                                
                                <strong>{{"Data e Hora de Devolução" | translate}}</strong>:
                                <p> {{carro.DataHoraDevolucao | date: "dd/MM/yyyy HH:mm"}}</p>
                            </div>
                            <div class="col">
                                <strong>{{"Local e Endereço de Retirada" | translate}}</strong>:
                                <p>{{carro.LocalOrigem}}<br>{{carro.EnderecoOrigem != null ? carro.EnderecoOrigem : "Sem endereço cadastrado" }}</p>

                                <strong>{{"Local e Endereco de Devolução" | translate}}</strong>:
                                <p>{{carro.LocalDevolucao}}<br>{{carro.EnderecoDevolucao != null ? carro.EnderecoDevolucao : "Sem endereço cadastrado" }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="">
                                            <th scope="col">
                                                {{"Ocupante" | translate}}
                                            </th>
                                            <th scope="col">
                                                {{"Núm. Portas" | translate}}
                                            </th>
                                            <th scope="col">
                                                {{"Bagagem" | translate}}
                                            </th>
                                            <th scope="col">
                                                {{"Ar Condicionado" | translate}}
                                            </th>
                                            <th scope="col">
                                                {{"Transmissão" | translate}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="">
                                            <td>
                                                {{carro.Pax}}
                                            </td>
                                            <td>
                                                {{carro.Portas}}
                                            </td>
                                            <td>
                                                {{carro.Bagagem}}
                                            </td>
                                            <td>
                                                {{carro.TemAirCondicionado ? ("Sim" | translate) : ("Não" | translate)}}
                                            </td>
                                            <td>
                                                {{carro.TransmissaoTipo | translate}}
                                            </td>
                                        </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row mt-3 flex-row-reverse">
                            <div class="col-12 d-flex justify-content-end">
                                <!-- <h4 class="text-primary">{{"Total" | translate}}:
                                    <span style="font-size: 1.2rem;" class="text-success">{{carro.MoedaListagem.Sigla}} {{carro.ValorTotalListagem | currency: carro.MoedaListagem.Sigla :'':'2.2-2'}}</span>
                                </h4> -->
                                <strong>{{ "Total" | translate}}</strong>: 
                                <strong class="price"> {{ carro.MoedaListagem.Sigla }} {{ carro.ValorTotalListagem | currency: carro.MoedaListagem.Sigla : '' : '2.2-2' }}</strong>
                            </div>
                            <div class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-end">
                                <h6>{{"Taxas Adm" | translate}}:
                                    <span style="font-size: 1.0rem;" class="text-success">{{carro.MoedaListagem.Sigla}} {{carro.TaxaAdm + carro.TaxaExtra + carro.TaxaAdmProtecoes | currency: carro.MoedaListagem.Sigla :'':'2.2-2'}}</span>
                                </h6>
                            </div>
                            <div class="col-12 col-md-2 d-flex flex-column justify-content-center align-items-end">
                                <h6>{{"Tarifa" | translate}}:
                                    <span style="font-size: 1.0rem;" class="text-success">{{carro.MoedaListagem.Sigla}} {{carro.ValorListagem | currency: carro.MoedaListagem.Sigla :'':'2.2-2'}}</span>
                                </h6>
                            </div>
                        </div>
                        <div class="row mt-2 d-flex flex-column justify-content-center">
                            <div class="col-12 row d-flex flex-row-reverse justify-content-center justify-content-md-between">
                                <div class="col-12 col-md-3 d-flex flex-row-reverse justify-content-center align-items-center justify-content-md-start gap-3">
                                    <button *ngIf="carro.Status == 0" (click)="abrirModal(contentCarroReserva, carro)" class="btn btn-success">
                                      {{'Reservar' | translate}}
                                    </button>
                                    <button *ngIf="(Orcamento.Status != 6 && (carro.Status == 3 || carro.Status == 4))"
                                      (click)="abrirModal(contentCarroCancela, carro)" class="btn btn-danger">
                                      {{'Cancelar' | translate}}
                                    </button>
                                    <button *ngIf="carro.Status == 0 || carro.Status == 8" class="btn btn-danger"
                                      (click)="confirmarRemoverCarro(carro)">
                                      {{'Excluir' | translate}}
                                    </button>
                                    <button *ngIf="(carro.Status == 3 || carro.Status == 4) && Orcamento.Status == 6" class="btn btn-success"
                                      [disabled]="!Orcamento.LiberarVoucher" (click)="imprimirVoucher('carro', carro)">
                                      {{(Orcamento.LiberarVoucher ? ('Voucher' | translate) : ('Voucher Pendente' | translate))}}
                                    </button>
                                </div>
                                <div class="col-12 col-md-3 mt-2 mt-md-0 d-flex align-items-center align-items-md-start flex-column" *ngIf="carro.PrazoCancelamento">
                                  <p class="text-warning mb-1">Prazo de Cancelamento:</p>
                                  <a class="text-muted"> {{carro.PrazoCancelamento | date :'dd/MM/yyyy'}}</a>
                                </div>
                                <div class="col-12 col-md-3 mt-2 mt-md-0 d-flex flex-column align-items-center align-items-md-start" *ngIf="carro.DataReserva">
                                  <p class="text-warning mb-1">Data da Reserva:</p>
                                  <a class="text-muted"> {{carro.DataReserva | date :'dd/MM/yyyy HH:mm:ss'}}</a>
                                </div>
                                <div class="col-12 col-md-3 mt-2 mt-md-0 d-flex flex-column align-items-center align-items-md-start" *ngIf="carro.DataCancelamento">
                                  <p class="text-warning mb-1">Data do Cancelamento:</p>
                                  <a class="text-muted"> {{carro.DataCancelamento | date :'dd/MM/yyyy HH:mm:ss'}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="mensagensSucessoCarro?.length > 10">
                            <div class="alert alert-success alert-dismissible" style="width: 100%">
                                <a class="close" data-bs-dismiss="alert" (click)="mensagensSucessoCarro = ''"
                                    aria-label="close">&times;</a>
                                <ul>
                                    <li>{{mensagensSucessoCarro}}</li>
                                </ul>
                            </div>
                        </div>
    
                        <div class="row" *ngIf="mensagensErroCarro?.length > 10">
                            <div class="alert alert-danger alert-dismissible" style="width: 100%">
                                <a class="close" data-bs-dismiss="alert" (click)="mensagensErroCarro = ''"
                                    aria-label="close">&times;</a>
                                <ul>
                                    <li>{{mensagensErroCarro}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="pacote">
          <div class='row'>
            <div class='col'></div>
            <div class='col'>
              <p class="title text-center"><i class="fas fa-star"></i> {{ "Pacote" | translate }}</p>
            </div>
            <div class='col'></div>
          </div>

          <div *ngFor="let pct of pacote; let a = index">
            <div class="row header-row">
              <div class="col">
                <h5>{{"Pacote" | translate}}</h5>
              </div>
            </div>
            <div class="row" id="description">
              <div class="col-5">
                <strong class="title"> {{ pct.Pacote.Nome + ' - ' + pct.Pacote.MinimoDeNoites + ' Noite(s)' }} </strong>
              </div>
            </div>
            <div class="row">
              <div class="col text-end">
                <div class="flex">
                  <strong>{{'Subtotal:' | translate}}</strong>
                  <p> {{pct.Quartos[0].MoedaListagem.Sigla}} {{ pct.ValorPacote | currency:'BRL':'': '2.2-2' }} </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class='col'>
                <details>
                  <summary><strong>{{'Observações' | translate}}</strong></summary>
                  <p class="text-justify" [innerHTML]="pct.Pacote.Introducao"></p>
                </details>
              </div>
            </div>
            <div class="row">
              <div class="col text-end">
                <strong>{{'Total:' | translate}} </strong>
                <strong class="price">{{pct.Quartos[0].MoedaListagem.Sigla}} {{ pct.ValorPacote | currency: 'BRL' : '' :
              '2.2-2' }}</strong>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <p>{{'Adicionado em: ' | translate}}{{ pct.Alteracoes.CriadoEm | date: 'dd/MM/yyyy'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div> -->

                <div class="pacote">
                    <div class='row d-flex justify-content-center'>
                        <div class='col-md-4'>
                            <p class="title text-center"><i class="fas fa-star"></i> {{ Orcamento.PacoteReservas.length
                                > 0 && Orcamento.PacoteReservas[0].Pacote.Evento ? "Evento" : "Pacote" | translate }}
                            </p>
                        </div>
                    </div>

                    <div class="row" *ngIf="mensagensSucessoPacote?.length > 10">
                        <div class="alert alert-success alert-dismissible" style="width: 100%">
                            <a class="close" data-bs-dismiss="alert" (click)="mensagensSucessoPacote = ''"
                                aria-label="close">&times;</a>
                            <ul>
                                <li>{{mensagensSucessoPacote}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row" *ngIf="mensagensErroPacote?.length > 10">
                        <div class="alert alert-danger alert-dismissible" style="width: 100%">
                            <a class="close" data-bs-dismiss="alert" (click)="mensagensErroPacote = ''"
                                aria-label="close">&times;</a>
                            <ul>
                                <li>{{mensagensErroPacote}}</li>
                            </ul>
                        </div>
                    </div>

                    <div *ngFor="let pacoteReserva of Orcamento.PacoteReservas; let a = index">
                        <div class="row mb-3">
                            <div class="col-md-8 m-3 title">
                                <h3>{{ pacoteReserva.Nome }}</h3>
                            </div>
                            <div class="col-md-8 m-3 title" *ngIf="pacoteReserva.Hoteis[0]">
                                <h5>Hotel escolhido: {{ pacoteReserva.Hoteis[0].Nome }}</h5>
                            </div>
                            <div class="col-md d-flex align-self-center justify-content-center"
                                [ngSwitch]="pacoteReserva.Status">
                                <h6>{{ pacoteReserva?.Pacote?.Evento ? "Status do Evento" : "Status do Pacote" |
                                    translate}}:</h6>
                                <span *ngSwitchCase="0" class="badge rounded-pill bg-secondary">{{"Pendente" | translate
                                    }}</span>
                                <span *ngSwitchCase="1" class="badge rounded-pill bg-secondary">{{"Aguardando" |
                                    translate }}</span>
                                <span *ngSwitchCase="2" class="badge rounded-pill bg-secondary">{{"Reserva Parcial" |
                                    translate}}</span>
                                <span *ngSwitchCase="3" class="badge rounded-pill bg-success">{{"Reservado" | translate
                                    }}</span>
                                <span *ngSwitchCase="4" class="badge rounded-pill bg-success">{{"Solicitado" | translate
                                    }}</span>
                                <span *ngSwitchCase="5" class="badge rounded-pill bg-success">{{"Emitido" | translate
                                    }}</span>
                                <span *ngSwitchCase="6" class="badge rounded-pill bg-success">{{"Pago" | translate
                                    }}</span>
                                <span *ngSwitchCase="7" class="badge rounded-pill bg-warning text-dark">{{"Problema
                                    Técnico" |
                                    translate }}</span>
                                <span *ngSwitchCase="8" class="badge rounded-pill bg-warning text-dark">{{"Cancelado" |
                                    translate
                                    }}</span>
                                <span *ngSwitchCase="9" class="badge rounded-pill bg-warning text-dark">{{"Excluído" |
                                    translate
                                    }}</span>
                                <span *ngSwitchCase="10" class="badge rounded-pill bg-warning text-dark">{{"Cancelamento
                                    com Problema" | translate }}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="row">
                                <div class="col-md" id="#accordion">
                                    <div id="headingOne">
                                        <a class="nav-link" style="cursor: pointer" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne" (click)="expandedDetails = !expandedDetails">
                                            {{pacoteReserva?.Pacote?.Evento ? "Detalhes do Evento" : "Detalhes do
                                            Pacote" | translate}}
                                            <i class="fas fa-chevron-down" *ngIf="!expandedDetails"></i>
                                            <i class="fas fa-chevron-up" *ngIf="expandedDetails"></i>
                                        </a>
                                    </div>
                                </div>


                                <div class="col-md d-flex align-self-center">
                                    <strong>{{'Ida' | translate}}&nbsp;:</strong>
                                    <span>{{ pacoteReserva.Checkin | date: 'dd/MM/yyyy' }}</span>
                                </div>

                                <div class="col-md d-flex align-self-center">
                                    <strong>{{ "Volta" | translate}}&nbsp;:</strong>
                                    <span>{{ pacoteReserva.Checkout | date: 'dd/MM/yyyy' }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div id="collapseOne" class="collapse collapsed" aria-labelledby="headingOne"
                                    data-parent="#accordion">
                                    <div class="p-2">
                                        <ul class="nav nav-tabs" id="tabInfoServico" role="tablist">
                                            <li class="nav-item" role="presentation"
                                                *ngFor="let inclui of pacoteReserva?.Pacote?.PacotesInclui; let x = index">
                                                <button class="nav-link" [ngClass]="x == 0 ? 'active' : ''"
                                                    id="tabservico{{x}}" data-bs-toggle="tab"
                                                    [attr.data-bs-target]="'#tabservicox' + x" type="button"
                                                    style="text-decoration: none !important;" role="tab"
                                                    [attr.aria-controls]="'tabservicox' + x"
                                                    [attr.aria-selected]="x == 0 ? true : false">{{inclui?.Titulo}}
                                                </button>
                                            </li>
                                        </ul>
                                        <div class="tab-content p-2 mt-2" id="tabInfoServicoContent">
                                            <div *ngFor="let inclui of pacoteReserva?.Pacote?.PacotesInclui; let x = index"
                                                class="tab-pane fade" id="tabservicox{{x}}"
                                                [ngClass]="x == 0 ? 'show active' : ''" role="tabpanel"
                                                [attr.aria-labelledby]="'tabservico' + x"
                                                [innerHTML]="inclui?.Descritivo"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5" *ngIf="pacoteReserva.Servicos.length > 0">
                                <div class="row text-center">
                                    <h3>{{"Serviços Inclusos" | translate}}</h3>
                                </div>

                                <div class="row m-3 p-3 border-bottom"
                                    *ngFor="let servicoPacote of pacoteReserva.Servicos">
                                    <div class="col-md-9 mb-3 pb-2">
                                        <h5 class="text-muted">{{ servicoPacote.Titulo }}
                                            <span
                                                *ngIf="servicoPacote?.ExtraIntegracao2?.CategoriaServico">({{servicoPacote?.ExtraIntegracao2?.CategoriaServico}})</span>
                                            <span
                                                *ngIf="usuario.EmpresaId != null">({{servicoPacote.Fornecedor}})</span>
                                        </h5>
                                    </div>

                                    <div class="col-md-3 d-flex justify-content-end">
                                        <div class="col d-flex align-self-center" [ngSwitch]="pacoteReserva.Status">
                                            <h6>{{ "Status do Serviço" | translate}}:</h6>
                                            <span *ngSwitchCase="0" class="badge rounded-pill bg-secondary">{{"Pendente"
                                                | translate }}</span>
                                            <span *ngSwitchCase="1"
                                                class="badge rounded-pill bg-secondary">{{"Aguardando" | translate
                                                }}</span>
                                            <span *ngSwitchCase="2" class="badge rounded-pill bg-secondary">{{"Reserva
                                                Parcial" |
                                                translate}}</span>
                                            <span *ngSwitchCase="3" class="badge rounded-pill bg-success">{{"Reservado"
                                                | translate }}</span>
                                            <span *ngSwitchCase="4" class="badge rounded-pill bg-success">{{"Solicitado"
                                                | translate }}</span>
                                            <span *ngSwitchCase="5" class="badge rounded-pill bg-success">{{"Emitido" |
                                                translate }}</span>
                                            <span *ngSwitchCase="6" class="badge rounded-pill bg-success">{{"Pago" |
                                                translate }}</span>
                                            <span *ngSwitchCase="7"
                                                class="badge rounded-pill bg-warning text-dark">{{"Problema Técnico" |
                                                translate }}</span>
                                            <span *ngSwitchCase="8"
                                                class="badge rounded-pill bg-warning text-dark">{{"Cancelado" |
                                                translate
                                                }}</span>
                                            <span *ngSwitchCase="9"
                                                class="badge rounded-pill bg-warning text-dark">{{"Excluído" | translate
                                                }}</span>
                                            <span *ngSwitchCase="10"
                                                class="badge rounded-pill bg-warning text-dark">{{"Cancelamento com
                                                Problema" | translate }}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-12 d-flex mb-2">
                                        <div class="col-md-2 d-flex" *ngIf="servicoPacote?.Localizador?.length > 2">
                                            <strong>{{"Loc" | translate}}:&nbsp;</strong> {{servicoPacote.Localizador}}
                                        </div><br>
                                        <!-- <div class="col-md d-flex" *ngIf="usuario.EmpresaId != null"><strong>{{"Fornecedor" | translate}}</strong>&nbsp; {{servicoPacote.Fornecedor}} </div>   -->
                                        <div class="col-md-2 d-flex"><strong>{{"Cidade" | translate}}:</strong>&nbsp;
                                            {{servicoPacote.Cidade?.Nome}} </div>
                                        <div class="col-md-2 d-flex"><strong>{{"Dt. Inclusão" |
                                                translate}}</strong>&nbsp; {{servicoPacote.Alteracoes.CriadoEm | date
                                            :'dd/MM/yyyy'}} </div>
                                        <div class="col-md d-flex" *ngIf="servicoPacote.PrazoCancelamentoWs">
                                            <strong>{{"P. Cancelamento" | translate}}</strong>&nbsp;
                                            {{servicoPacote.PrazoCancelamentoWs | date :'dd/MM/yyyy'}} </div>
                                        <div class="col-md-2 d-flex" *ngIf="servicoPacote.CodigoCliente != null">
                                            <strong>{{"Código do Cliente" | translate}}</strong>&nbsp;
                                            {{servicoPacote.Alteracoes.CriadoEm | date :'dd/MM/yyyy'}} </div>
                                        <!-- <div class="col-md d-flex">{{servicoPacote.Adt}} <strong>&nbsp;{{"Adulto" | translate}}(s)</strong></div>
                  <div class="col-md d-flex">{{servicoPacote.Chd}} <strong>&nbsp;{{'Criança' | translate}}(s)</strong></div> -->
                                        <!-- <div class="col-md-2 d-flex"><strong>{{"Valor Serviço" | translate}}</strong>: {{servicoPacote.MoedaListagem.Sigla}} {{servicoPacote.ValorListagem | currency:'BRL':'':'2.2-2'}}</div> -->
                                    </div>

                                    <div class="col-md-12 p-2 m-2">
                                        <h6 class="text-center">{{"Descrição do Serviço" | translate}}</h6>
                                        <p class="text-center card-text p-2 mb-2" [innerHTML]="servicoPacote.Descricao">
                                        </p>
                                    </div>

                                    <div class="accordion col-md-4" id="accordionPerguntas"
                                        *ngIf="servicoPacote.Perguntas && servicoPacote.Perguntas.length > 0">
                                        <div class="accordion-item">
                                            <div class="accordion-header" id="headingPerguntas">
                                                <a data-bs-toggle="collapse" class="nav-link accordion-button collapsed"
                                                    style="cursor: pointer" data-bs-target="#collapsePerguntas"
                                                    aria-expanded="false" aria-controls="collapsePerguntas">
                                                    Perguntas <i class="fas fa-chevron-down"></i>
                                                </a>
                                            </div>
                                            <div id="collapsePerguntas" class="accordion-collapse collapse"
                                                aria-labelledby="headingPerguntas" data-bs-parent="#accordionPerguntas">
                                                <div class="accordion-body">
                                                    <div class="mt-4 row"
                                                        *ngIf="servicoPacote.Perguntas && servicoPacote.Perguntas.length > 0">
                                                        <div class="col col-md-3"
                                                            *ngFor="let pergunta of servicoPacote.Perguntas">
                                                            <h6>{{pergunta.Pergunta}}</h6>
                                                            <p>R: {{pergunta.Resposta}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12"
                                        *ngIf="servicoPacote.PoliticaCancelamento != '' || servicoPacote.PoliticaCancelamento != null">
                                        <details>
                                            <summary><strong>{{'Política de Cancelamento' | translate}}</strong>
                                            </summary>
                                            <p class="text-justify" [innerHTML]="servicoPacote.PoliticaCancelamento">
                                            </p>
                                        </details>
                                    </div>
                                    <!-- <div class="col-md-4">
                <details>
                  <summary><strong>{{'Observações' | translate}}</strong></summary>
                  <p class="text-justify" [innerHTML]="servicoPacote.Descricao"></p>
                </details>
              </div> -->
                                </div>
                            </div>

                            <!-- <div class="card-body table-full-width table-responsive">
            <table class="table table-hover table-striped">
              <thead>
                <th>{{'Categoria' | translate}}</th>
                <th>{{'Taxa' | translate}}</th>
                <th>{{'Valor' | translate}}</th>
              </thead>
              <tbody>
                <tr *ngFor="let pacoteReservas of Orcamento.PacoteReservas">
                  <td *ngFor="let Hotel of pacoteReservas?.Hoteis">
                    <div *ngFor="let Quartos of Hotel.Quartos">{{Quartos.Descricao}}</div>
                  </td>
                  <td>{{pacoteReservas.MoedaListagem.Sigla}} {{pacoteReservas.ValorTaxaListagem}}</td>
                  <td>{{pacoteReservas.MoedaListagem.Sigla}} {{pacoteReservas.ValorListagem}}</td>
                </tr>
              </tbody>
            </table>
          </div> -->

                            <div class="row mb-3">
                                <div class="col">
                                    <p>{{"Adicionado em" | translate}}: {{ pacoteReserva?.Alteracoes?.CriadoEm | date:
                                        'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                            <div class="col text-end">

                            </div>

                            <div class="col text-end">
                                <button class="btn btn-outline-danger"
                                    *ngIf="pacoteReserva.Status == 0 || pacoteReserva.Status == 8"
                                    (click)="removerPacoteReserva(pacoteReserva.Id)">{{ "Excluir" | translate
                                    }}</button>

                                <button *ngIf="pacoteReserva.Status == 3 && Orcamento.Status!=6"
                                    class="btn btn-outline-danger"
                                    (click)="abrirModal(contentCancelaPacote, pacoteReserva)">{{ "Cancelar" | translate
                                    }}</button>
                                <button *ngIf="pacoteReserva.Status == 0" type="button" class="btn btn-success"
                                    (click)="abrirModal(contentReservaPacote, pacoteReserva)">Reservar</button>

                                <button *ngIf="pacoteReserva.Status == 3 && Orcamento.Status ==6" type="button"
                                    class="btn btn-success" (click)="imprimirVoucher('pacote',pacoteReserva)"
                                    [disabled]="!Orcamento.LiberarVoucher">
                                    <span *ngIf="!Orcamento.LiberarVoucher" class="fas fa-exclamation-circle"></span>
                                    <span *ngIf="Orcamento.LiberarVoucher" class="fas fa-check-circle"></span>
                                    {{Orcamento.LiberarVoucher ? 'Voucher' : 'Voucher Pendente'}}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- <div class="pagamento">
        <p class="title text-center"><i class="fas fa-file-alt"></i> {{"Totais por Opção" | translate }}</p>
        <div class="hoteis mt-3" *ngFor="let hotel of Orcamento.Hoteis; let a = index" >
          <div *ngFor="let servico of Orcamento.Servicos; let x = index">
            <div *ngIf="!carregando">
              {{ somaServico(x, Orcamento.Servicos[x].ValorVenda) }}
            </div>
          </div>
          <div class='row'>
            <div class='col flex opcoesOrcamento'>
              <div>
                <h5>Opção {{ a + 1 }}</h5>
              </div>
              <div>
                <button *ngIf="hotel.Status == 8" class="btn btn-outline-danger" (click)="confirmarRemoverHotel(hotel)">{{ "Excluir Hotel" | translate }}</button>
                <button *ngIf="hotel.Status == 3" class="btn btn-outline-danger" (click)="carregar(hotel); hotelLoadded = !hotelLoadded" data-bs-toggle="modal" data-bs-target="#cancelarHotel">{{ "Cancelar" | translate }}</button>
                <button *ngIf="hotel.Status == 3" class="btn btn-success" (click)="carregar(Orcamento); pagamentoLoadded = !pagamentoLoadded" data-bs-toggle="modal" data-bs-target="#pagamentoModal">{{'Pagar' | translate}}</button>
              </div>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col-2">Hotel</th>
                <th scope="col">Entrada</th>
                <th scope="col">Saída</th>
                <th scope="col" *ngIf="Orcamento.Aereos[0]">Total Aéreo</th>
                <th scope="col">Total Hotel</th>
                <th scope="col" *ngIf="Orcamento.Servicos[0]">Total Serviços</th>
                <th scope="col-2">Total Opção</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="col-2">
                  {{ hotel.Nome }}
                </th>
                <td class="col">
                  {{ hotel.Checkin | date: 'dd/MM/yyyy' }}
                </td>
                <td class="col">
                  {{ hotel.Checkout | date: 'dd/MM/yyyy' }}
                </td>
                <td class="col" *ngIf="Orcamento.Aereos[0]">
                  {{ Orcamento.Aereos[0].MoedaListagem.Simbolo }} {{ Orcamento.Aereos[0].ValorVenda | currency:'BRL':'':'2.2-2' }}
                </td>
                <td class="col">
                  {{ hotel.Quartos[0].MoedaListagem.Simbolo }} {{ hotel.ValorTotalListagem | currency:'BRL':'':'2.2-2' }}
                </td>
                <td class="col" *ngIf="!servicoCarregando && Orcamento.Servicos[0]">
                  {{ Orcamento.Servicos[0].MoedaListagem.Simbolo }} {{ totalServico | currency:'BRL':'':'2.2-2' }}
                </td>
                <td class="col-2" *ngIf="!servicoCarregando">

                  <strong class="validacaoPreço4" *ngIf="Orcamento.Aereos[0] == null && Orcamento.Servicos[0] == null">
                    {{ hotel.Quartos[0].MoedaListagem.Simbolo }} {{ hotel.ValorTotalListagem | currency:'BRL':'':'2.2-2' }}
                  </strong>

                  <strong class="validacaoPreço3" *ngIf="(Orcamento.Aereos[0]) && (Orcamento.Servicos[0] == null)">
                    {{ hotel.Quartos[0].MoedaListagem.Simbolo }} {{ hotel.ValorTotalListagem + Orcamento.Aereos[0].ValorVenda | currency:'BRL':'':'2.2-2' }}
                  </strong>

                  <strong class="validacaoPreço2" *ngIf="(Orcamento.Aereos[0] == null) && (Orcamento.Servicos[0])">
                    {{ hotel.Quartos[0].MoedaListagem.Simbolo }} {{ hotel.ValorTotalListagem + totalServico | currency:'BRL':'':'2.2-2' }}
                  </strong>

                  <strong class="validacaoPreço1" *ngIf="(Orcamento.Aereos[0]) && (Orcamento.Servicos[0])">
                    {{ hotel.Quartos[0].MoedaListagem.Simbolo }} {{ totalServico + hotel.ValorTotalListagem + Orcamento.Aereos[0].ValorVenda | currency:'BRL':'':'2.2-2' }}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

                <div class="pagamento" *ngIf="Orcamento.PacoteReservas.length == 0">
                    <p class="title text-center"><i class="fas fa-file-alt"></i> {{ "Totais por Opção" | translate }}
                    </p>
                    <div class="hoteis mt-3" *ngFor="let opcao of opcoesValores; let a = index">
                        <div class='row'>
                            <div class='col flex opcoesOrcamento'>
                                <div>
                                    <h5 style="display: inline;">{{"Opção" | translate}} {{opcao.opcao}}</h5>
                                    <h5 style="float: right;">{{"Total Opção" | translate}} {{opcao.opcao}}:
                                        {{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                                        {{opcao.valor | currency:'BRL':'':'1.2-2'}}
                                    </h5>
                                </div>

                            </div>
                        </div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col-2">{{"Hotel" | translate}}</th>
                                    <th scope="col">{{"Entrada" | translate}}</th>
                                    <th scope="col">{{"Saída" | translate}}</th>
                                    <th scope="col">{{"Incluso" | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dadoshoteis of opcao.dados">
                                    <th scope="row" class="col-2">
                                        {{ dadoshoteis.Nome }}
                                    </th>
                                    <td class="col">
                                        {{ dadoshoteis.CheckinOpcao}}
                                    </td>
                                    <td class="col">
                                        {{ dadoshoteis.CheckoutOpcao}}
                                    </td>
                                    <td>
                                        {{incluso}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <p class="title text-center"><i class="fas fa-dollar-sign"></i> {{"Pagamento" | translate }}</p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{"Total" | translate }}</th>
                                <th>{{"Valor Pago" | translate}}</th>
                                <!-- <th>{{"Desconto" | translate}}</th> -->
                                <th>{{"Saldo Pendente" | translate}}</th>
                                <th>{{"Juros" | translate}}</th>
                                <th>{{"Total(com juros)" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                                    {{Orcamento.ValorTotal | currency:'BRL':'':'1.2-2'}}
                                </td>
                                <td>{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                                    {{(Orcamento.ValorPago - Orcamento.ValorJuros) | currency:'BRL':'':'1.2-2'}}
                                </td>
                                <!-- <td *ngIf="Orcamento.Desconto == 0">-</td>
              <td *ngIf="Orcamento.Desconto > 0">{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                {{Orcamento.Desconto | currency:'BRL':'':'1.2-2'}}
              </td> -->
                                <td>{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                                    {{ ( Orcamento.ValorTotal - Orcamento.ValorPago) | currency:'BRL':'':'1.2-2'}}
                                </td>
                                <td>{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                                    {{Orcamento.ValorJuros | currency:'BRL':'':'1.2-2'}}
                                </td>
                                <td>{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}}
                                    {{ (Orcamento.ValorTotal + Orcamento.ValorJuros) | currency:'BRL':'':'1.2-2'}}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div class="text-end">
                        <button type="button" class="btn btn-success" (click)="carregar(Orcamento, 'pagamento')"
                            *ngIf="Orcamento.Status != 6">{{ "Pagar" | translate }}</button>
                        <button #pagamento data-bs-toggle="modal" data-bs-target="#pagamentoModal"
                            style="display: none;"></button>
                    </div>
                </div>

                <div *ngIf="pagamentosRealizados.length > 0">
                    <p class="title text-center"><i class="fas fa-dollar-sign"></i> {{ "Pagamentos Realizados" |
                        translate }}</p>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>{{"Código" | translate}}</th>
                                <th>{{"Forma" | translate}}</th>
                                <th>{{"Data" | translate}}</th>
                                <th>{{"Valor" | translate}}</th>
                                <th>{{"Status" | translate}}</th>
                                <th>{{"Ações" | translate}}</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let pgto of pagamentosRealizados">
                                <td>{{ pgto.Id}}</td>
                                <td>{{ pgto.CondicaoPagamento.FormaPagamento.Descricao}} -
                                    {{pgto.CondicaoPagamento.Descricao}}</td>
                                <td>{{ pgto.Alteracoes.CriadoEm | date: 'dd/MM/yyyy'}}</td>
                                <td>{{Orcamento.Agencia.Empresa.MoedaPagamento.Sigla}} {{ pgto.Valor
                                    |currency:'BRL':'':'1.2-2'}}</td>
                                <td [ngSwitch]="pgto.Status">
                                    <span *ngSwitchCase="0" class="badge rounded-pill bg-secondary">Pendente</span>
                                    <span *ngSwitchCase="1" class="badge rounded-pill bg-secondary">Enviado</span>
                                    <span *ngSwitchCase="2" class="badge rounded-pill bg-success">Confirmado</span>
                                    <span *ngSwitchCase="3"
                                        class="badge rounded-pill bg-warning text-dark">Recusado</span>
                                    <span *ngSwitchCase="4" class="badge rounded-pill bg-success">Aprovado</span>
                                    <span *ngSwitchCase="5"
                                        class="badge rounded-pill bg-warning text-dark">Cancelado</span>
                                    <span *ngSwitchCase="6"
                                        class="badge rounded-pill bg-warning text-dark">Estornado</span>
                                    <span *ngSwitchCase="7"
                                        class="badge rounded-pill bg-warning text-dark">Excluído</span>
                                </td>
                                <td [ngSwitch]="pgto.Status">
                                    <div *ngSwitchCase="0">
                                        <button *ngIf="pgto.TipoPagamento == 2 && usuario.EmpresaId != null"
                                            class="btn btn-info btn-sm" (click)="consultarPagamento(pgto)"
                                            title="Consultar">Consultar
                                        </button>
                                        <button
                                            *ngIf="pgto.TipoPagamento == 2 && pgto.Link != null && pgto.Link && pgto.ChaveLink!=''"
                                            class="btn btn-info btn-sm m-1" (click)="abrirLinkPagamento(pgto)"
                                            title="Acessar Link">
                                            Acessar Link <i class="fas fa-link"></i>
                                        </button>
                                    </div>
                                    <div *ngSwitchCase="4">
                                        <button *ngIf="pgto.TipoPagamento == 2" class="btn btn-danger btn-sm"
                                            (click)="estornar(pgto,i)" title="Estornar">Estornar </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="mb-5" *ngIf="usuario.EmpresaId != null">
                    <app-log texto="{{'Ver Log de alterações' | translate}}" [showDetalhes]="true" [alteracoes]="Orcamento.Alteracoes"
                        [entidade]="'Reserva'" [chave]="Orcamento.Id">Ver Alterações</app-log>
                </div>
            </div>
        </div>

        <div class="modal fade bd-example-modal-lg" tabindex="-1" id="selecionaConsultor" role="dialog"
            aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <app-selecionar-consultor *ngIf="Orcamento.AgenciaId" [reserva]="Orcamento"
                            (selecionou)="selecionaConsultor($event)"></app-selecionar-consultor>
                    </div>
                    <div class="modal-footer">
                        <div class='row'>
                            <div class='col text-end'>
                                <button class="btn btn-success" data-bs-dismiss="modal">{{"Fechar" |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" tabindex="-1" id="selecionaAgente" role="dialog"
            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{'Selecionar Agente' | translate}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="form-check">
                                <div class="row">
                                    <div class="col">
                                        <div *ngFor="let agente of agenteLista" class="item">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                (change)="changeAgente($event)" value="{{agente.Id}}">
                                            <label>{{ agente.Nome }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button (click)="mudarAgente();" type="button" class="btn btn-success" data-bs-dismiss="modal">
                            Selecionar
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="reservarAereo" role="dialog"
        aria-labelledby="Modal Reserva Aereo" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"
        *ngIf="aereoLoadded">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{'Reserva de aéreo' | translate}}</h4>
                </div>
                <div class="modal-body">
                    <app-aereo-reserva [aereo]="objeto" (alterou)="atualizarAereo($event)"
                        (fechou)="aereoLoadded = false"></app-aereo-reserva>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-lg" tabindex="-1" id="emitirAereo" role="dialog"
        aria-labelledby="Modal Emitir Aereo" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false"
        *ngIf="aereoLoadded">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">{{'Emissão de aéreo' | translate}}</h4>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-aereo-emite [aereo]="objeto" (alterou)="atualizarAereo($event)"
                        (fechou)="aereoLoadded = false">
                    </app-aereo-emite>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-lg" id="cancelarAereo" role="dialog" aria-labelledby="Modal Cancelar Aereo"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true"
        *ngIf="aereoLoadded">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <app-aereo-cancela [aereo]="objeto" (alterou)="atualizarAereo($event)" (fechou)="aereoLoadded = false">
                </app-aereo-cancela>
            </div>
        </div>
    </div>

    <div class="modal fade" id="reservarHotel" aria-labelledby="Modal Reservar Hotel" role="dialog" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true" *ngIf="hotelLoadded">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <app-hotel-reserva [hotel]="objeto" (alterou)="atualizarHotel($event)" (fechou)="hotelLoadded = false">
                </app-hotel-reserva>
            </div>
        </div>
    </div>

    <div class="modal fade" id="reservarServico" aria-labelledby="Modal Reservar Servico" role="dialog"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true"
        *ngIf="servicoLoadded">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <app-servico-reserva [servico]="objeto" (alterou)="atualizarServico($event)"
                    (fechou)="servicoLoadded = false" [orcamento]="Orcamento">
                </app-servico-reserva>
            </div>
        </div>
    </div>

    <div class="modal fade" id="cancelarServico" aria-labelledby="Modal Cancelar Servico" role="dialog"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <app-servico-cancela [servico]="objeto" (alterou)="atualizarServico($event)"
                    (fechou)="servicoLoadded = false">
                </app-servico-cancela>
            </div>
        </div>
    </div>

    <div class="modal fade bd-example-modal-xl" aria-labelledby="Modal Pagamento" id="pagamentoModal" role="document"
        aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true"
        *ngIf="pagamentoLoadded">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <app-reserva-pagamentos [reserva]="objeto" (alterou)="atualizarPagamentos($event)"
                    (fechou)="pagamentoLoadded = false"></app-reserva-pagamentos>
            </div>
        </div>
    </div>

    <ng-template #contentReservaPacote let-modal>
        <app-reserva-pacote [Pacote]="objModal" (alterou)="atualizarOrcamento()"></app-reserva-pacote>
    </ng-template>

    <ng-template #contentCancelaPacote let-modal>
        <app-cancela-pacote [Pacote]="objModal" (alterou)="atualizarOrcamento()"></app-cancela-pacote>
    </ng-template>

    <ng-template let-modal #contentLaminas>
        <app-laminas [Reserva]="Orcamento"></app-laminas>
    </ng-template>

    <ng-template let-modal #contentCarroReserva>
        <carro-reserva (alterou)="atualizarCarro($event)" [carro]="objModal" [paxes]="Orcamento.Pessoas"></carro-reserva>
    </ng-template>

    <ng-template let-modal #contentCarroCancela>
        <carro-cancela (alterou)="atualizarCarro($event)" [carro]="objModal"></carro-cancela>
    </ng-template>